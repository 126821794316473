<template>
  <div class="bg pgbg" :class="$route.query.subjectName">
    <div class="class--con">
      <class-but @forward="forward" @back="backOf" />
    </div>

  </div>
</template>

<script>
import ClassBut from "./classBut";
export default {
  name: "SmallGameCover",
  components: {ClassBut},
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    forward(){
      this.$router.push({
        name: 'PageSecond',
        query: this.$route.query
      })
    },
    backOf(){
      this.$router.back()
    },
  },
}
</script>

<style lang="less" scoped>
@import "reportClass.less";
.pgbg{
  background-image: url("~@/assets/image/conference/tyxyx.png");
}
</style>
